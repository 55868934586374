<template>
  <div>Create</div>
</template>

<script>
/**
 * 创建对象数据组件
 */
export default {};
</script>

<style lang="scss" scoped>
</style>